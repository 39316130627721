.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 80vh;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  padding-right: 40px;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #333;
}

.hero-content h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #1a5631;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #666;
  line-height: 1.6;
}

.hero-buttons {
  display: flex;
  gap: 15px;
}

.btn {
  padding: 12px 24px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  font-size: 1rem;
}

.primary-btn {
  background-color: #1a5631;
  color: white;
}

.primary-btn:hover {
  background-color: #1a5631;
  transform: translateY(-2px);
}

.secondary-btn {
  background-color: transparent;
  color: #0070f3;
  border: 2px solid #0070f3;
}

.secondary-btn:hover {
  background-color: rgba(0, 112, 243, 0.1);
  transform: translateY(-2px);
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 960px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
    padding: 60px 20px;
  }

  .hero-content {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .hero-buttons {
    justify-content: center;
  }
}