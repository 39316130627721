.services-section {
  padding: 80px 20px;
  background-color: white;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-image {
  margin: -30px -30px 20px -30px;
  height: 180px;
  overflow: hidden;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.service-card:hover .service-image img {
  transform: scale(1.1);
}

.service-icon {
  font-size: 2.5rem;
  color: #1a5631;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.service-card p {
  color: #666;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .services-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}