.navbar {
  background-color: rgba(255, 255, 255, 0.95);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo-img {
  height: 50px;
  width: auto;
  margin-right: 15px;
}

.navbar-logo-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-logo-text h1 {
  font-size: 1.6rem;
  font-weight: 700;
  color: #333;
  margin: 0;
  letter-spacing: -0.5px;
  transition: color 0.3s ease;
}

.navbar-logo:hover .navbar-logo-text h1 {
  color: #1a5631;
}

.slogan {
  color: #1a5631;
}

.nav-links::after {
  background-color: #1a5631;
}

.nav-links:hover {
  color: #1a5631;
}

.menu-icon:hover {
  color: #1a5631;
}

.slogan {
  font-size: 0.85rem;
  color: #1a5631;
  margin: 0;
  font-weight: 500;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.nav-item {
  height: 80px;
  padding: 0 15px;
  position: relative;
}

.nav-links {
  color: #333;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
}

.nav-links::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #0070f3;
  transition: width 0.3s ease;
}

.nav-links:hover {
  color: #0070f3;
}

.nav-links:hover::after {
  width: 100%;
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease;
}

.menu-icon:hover {
  color: #0070f3;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background-color: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  .nav-item {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    padding: 20px;
    height: auto;
  }

  .nav-links::after {
    display: none;
  }

  .menu-icon {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
  }
}