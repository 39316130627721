.footer {
  background-color: #222;
  color: #fff;
  padding: 60px 20px 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 50px;
}

.footer-logo h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #fff;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo img {
  width: 150px;
  height: auto;
  margin-bottom: 15px;
}

.footer-logo p {
  color: #1a5631; /* Green color for slogan */
  font-size: 1rem;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #fff;
  position: relative;
  padding-bottom: 10px;
}

.footer-section h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: #1a5631; /* Changed from #0070f3 to dark green */
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #1a5631; /* Changed from #0070f3 to dark green */
}

.footer-section p {
  color: #ccc;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.footer-section p i {
  margin-right: 10px;
  color: #1a5631; /* Changed from #0070f3 to dark green */
}

.footer-social h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #fff;
  position: relative;
  padding-bottom: 10px;
}

.footer-social h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: #1a5631; /* Changed from #0070f3 to dark green */
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #333;
  border-radius: 50%;
  color: #fff;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  background-color: #1a5631; /* Changed from #0070f3 to dark green */
  transform: translateY(-5px);
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #444;
}

.footer-bottom p {
  color: #ccc;
  font-size: 0.9rem;
}

@media screen and (max-width: 960px) {
  .footer-container {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  
  .footer-links {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .footer-logo, .footer-social {
    text-align: center;
  }
  
  .footer-section h3::after, .footer-social h3::after {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .social-icons {
    justify-content: center;
  }
}