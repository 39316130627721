.about-section {
  padding: 80px 20px;
  background-color: #f9f9f9;
}

.about-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  gap: 50px;
  align-items: center;
}

.about-image {
  flex: 1;
}

.about-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-content {
  flex: 1;
}

.about-content h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.about-content p {
  color: #666;
  margin-bottom: 20px;
  line-height: 1.6;
}

.about-features {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.feature {
  text-align: center;
  flex: 1;
}

.feature i {
  font-size: 2rem;
  color: #1a5631;
  margin-bottom: 15px;
}

.feature h4 {
  font-size: 1.2rem;
  color: #333;
}

@media screen and (max-width: 960px) {
  .about-container {
    flex-direction: column;
  }
  
  .about-image {
    margin-bottom: 30px;
  }
  
  .about-features {
    flex-direction: column;
    gap: 20px;
  }
}