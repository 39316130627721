.products-section {
  padding: 80px 20px;
  background-color: #f9f9f9;
}

.section-header {
  text-align: center;
  margin-bottom: 50px;
}

.section-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 15px;
}

.section-header p {
  font-size: 1.1rem;
  color: #666;
  max-width: 700px;
  margin: 0 auto;
}

.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.product-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.product-image {
  height: 200px;
  overflow: hidden;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.product-card:hover .product-image img {
  transform: scale(1.1);
}

.product-info {
  padding: 20px;
}

.product-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.product-info p {
  color: #666;
  margin-bottom: 20px;
  line-height: 1.6;
}

.product-btn {
  background-color: #1a5631;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.product-btn:hover {
  background-color: #005bc0;
}

@media screen and (max-width: 768px) {
  .products-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}